'use client';

// import HeroSection from '@/app/ui/sections/hero/HeroSection';
import CoinTokenSection from '@/app/ui/sections/coin-token/CoinTokenSection';
import NextGenSection from '@/app/ui/sections/next-gen/NextGenSection';
import FindYourWaySection from '@/app/ui/sections/find-your-way/FindYourWaySection';
import RoadMapSection from '@/app/ui/sections/roadmap/RoadMapSection';
import Footer from '@/app/ui/footer/Footer';
import TokenomicsSection from '@/app/ui/sections/tokenomics/TokenomicsSection';
import { Parallax } from 'react-scroll-parallax';
import Image from 'next/image';
import HeroSection from '@/app/ui/sections/hero/HeroSection';
import FeaturedInSection from '@/app/ui/sections/featured-in/FeaturedInSection';
import heroLeftClouds from './ui/images/hero_left.webp';
import heroRightClouds from './ui/images/hero_right.webp';
import roadmapLeftClouds from './ui/images/roadmap_left.webp';
import roadmapRightClouds from './ui/images/roadmap_right.webp';

export default function Home() {
  return (
    <main className="max-w-screen relative overflow-x-hidden">
      <HeroSection />
      <div className="pointer-events-none absolute left-0 right-0 z-10 pt-[5rem] lg:pt-[23rem] ">
        <Parallax
          className="absolute bottom-[0px] left-0 h-full w-2/4"
          translateX={[0, -100]}
        >
          <Image
            src={heroLeftClouds}
            fill
            alt="Left side clouds"
            className="!inset-y-auto !bottom-0 !h-auto"
            sizes="50vw"
          />
        </Parallax>
        <Parallax
          className="absolute bottom-[0px] right-0 h-full w-2/4"
          translateX={[0, 100]}
        >
          <Image
            src={heroRightClouds}
            fill
            alt="Right side clouds"
            className="!inset-y-auto !bottom-0 !h-auto"
            sizes="50vw"
          />
        </Parallax>
      </div>
      <FeaturedInSection />
      <CoinTokenSection />
      <NextGenSection />
      <FindYourWaySection />
      <RoadMapSection />
      <div className="pointer-events-none absolute left-0 right-0 z-10 pt-16 lg:pt-[25rem]">
        <Parallax
          className="absolute bottom-[0px] left-0 h-full w-2/4"
          translateX={[0, -100]}
        >
          <Image
            src={roadmapLeftClouds}
            fill
            alt="Left side clouds"
            className="!inset-y-auto !bottom-0 !h-auto"
            sizes="50vw"
          />
        </Parallax>
        <Parallax
          className="absolute bottom-[0px] right-0 h-full w-2/4"
          translateX={[0, 100]}
        >
          <Image
            src={roadmapRightClouds}
            fill
            alt="Right side clouds"
            className="!inset-y-auto !bottom-0 !h-auto"
            sizes="50vw"
          />
        </Parallax>
      </div>
      <TokenomicsSection />
      <Footer />
    </main>
  );
}
