'use client';

import SectionsWrapper from '@/app/ui/SectionsWrapper';
import Icon from '@/app/ui/icons/Icon';
import { supercell } from '@/app/ui/font';
import useWindowSize from '@/app/hooks/useWindowSize';
import DesktopCards from '@/app/ui/sections/roadmap/DesktopCards';
import MobileCards from '@/app/ui/sections/roadmap/MobileCards';
import { useEffect, useRef } from 'react';
import { useNavigation } from '@/app/contexts/Navigation';
import SOC from '@/app/db/soc';

export default function RoadMapSection() {
  const { isMobile } = useWindowSize();
  const { setRefs } = useNavigation();
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      setRefs?.((prev) => ({
        ...prev,
        roadmap: ref.current,
      }));
    }
  }, [ref]);
  return (
    <SectionsWrapper className="bg-roadmap bg-cover bg-center lg:min-h-screen">
      <div
        ref={ref}
        className="container relative flex min-h-screen cursor-default flex-col justify-between gap-[50px] lg:gap-20"
      >
        <div className="relative flex w-max self-center">
          <Icon
            name={isMobile ? 'roadmapHangSmall' : 'roadmapHang'}
            width={isMobile ? 299 : 510}
            height={isMobile ? 144 : 217}
          />
          <h1
            className={`${supercell.className} absolute left-2/4 top-16 -translate-x-2/4 pt-1.5 text-[42px] text-baby-blue lg:top-24 lg:pt-0 lg:text-[63px]`}
          >
            Roadmap
          </h1>
        </div>
        {isMobile ? <MobileCards /> : <DesktopCards />}
        {/* TODO: user cant see on desktop */}
        <div className="relative flex h-[329px] w-[280px] items-center justify-center self-center lg:w-[957px]">
          <div className="absolute">
            <Icon
              name={isMobile ? 'roadmapBlockMobile' : 'roadmapBlock'}
              width={isMobile ? 393 : 957}
              height={isMobile ? 304 : 329}
            />
          </div>
          <div className="relative flex flex-col gap-7 text-center">
            <p className="quiroh-font text-[24px] font-semibold text-baby-blue">
              Follow our journey latest updates
            </p>
            <div className="flex justify-center gap-10 text-navy-blue">
              <a
                aria-label="Telegram"
                className="hover:text-ice-blue"
                href={SOC.telegram}
                target="_blank"
              >
                <Icon name="telegram" width={52} height={52} />
              </a>
              <a
                aria-label="Discord"
                className="hover:text-ice-blue"
                href={SOC.discord}
                target="_blank"
              >
                <Icon name="discord" width={62} height={48} />
              </a>
              <a
                aria-label="X"
                className="hover:text-ice-blue"
                href={SOC.x}
                target="_blank"
              >
                <Icon name="twitter" width={52} height={52} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </SectionsWrapper>
  );
}
