import Icon from '@/app/ui/icons/Icon';
import { supercell } from '@/app/ui/font';

type CardType = {
  className: string;
  year: number;
  quarter: string;
  list: string[];
};

export default function Card({ className, year, quarter, list }: CardType) {
  return (
    <div
      className={`${className} relative z-10 h-[310px] w-[270px] duration-200 hover:rotate-0 hover:scale-110`}
    >
      <div className="absolute">
        <Icon name="bigCard" width={265} height={339} viewBox="0 0 265 339" />
      </div>
      <div className="relative px-5 pt-10 text-center">
        <p className={`${supercell.className} text-[17px] text-vivid-orange`}>
          {year}
        </p>
        <p
          className={`${supercell.className} text-[41px] leading-[100%] text-baby-blue`}
        >
          {quarter}
        </p>
        <ul className="quiroh-font mt-6 rotate-[-2deg] list-disc pl-6 text-left leading-[150%] text-cool-mist">
          {list.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
