'use client';

import SectionsWrapper from '@/app/ui/SectionsWrapper';
import { supercell } from '@/app/ui/font';
import Image from 'next/image';
import Icon, { iconsList } from '@/app/ui/icons/Icon';
import { useEffect, useRef, useState } from 'react';
import { useNavigation } from '@/app/contexts/Navigation';
import useWindowSize from '@/app/hooks/useWindowSize';
import clsx from 'clsx';
import hamster from './images/hamster_flashlight.webp';

const info = [
  {
    title: 'AI-powered cloud computing',
    description: 'Optimized rendering speed and stable game performance',
    icon: 'smallCoin',
    light: 'firstLight',
    hoverClass: 'lg:bg-info-block-gradient',
    flashlight: 'bottom-[-247px] left-[-287px]',
  },
  {
    title: 'In-game NFT purchasing & minting',
    description: 'Buy, mint and trade NFTs in-game to unlock new skills and',
    icon: 'controller',
    light: 'secondLight',
    hoverClass: 'lg:bg-info-block-2-gradient',
    flashlight: 'bottom-[-230px] left-[-287px]',
  },
  {
    title: 'AI-generated environments',
    description: 'Dynamic and non-repetitive haunted house rooms',
    icon: 'ghost',
    light: 'thirdLight',
    hoverClass: 'lg:bg-info-block-gradient',
    flashlight: 'bottom-[-202px] left-[-300px]',
  },
  {
    title: 'Stable internal economy',
    description: 'Fair and rewarding gameplay for everyone',
    icon: 'doors',
    light: 'fourthLight',
    hoverClass: 'lg:bg-info-block-gradient',
    flashlight: 'bottom-[-119px] left-[-300px]',
  },
];

export default function NextGenSection() {
  const ref = useRef<HTMLDivElement | null>(null);
  const { setRefs } = useNavigation();
  const { isMobile } = useWindowSize();
  const [activeItem, setActiveItem] = useState<number>(0);

  useEffect(() => {
    if (ref.current) {
      setRefs?.((prev) => ({ ...prev, nextgen: ref.current }));
    }
  }, [ref]);
  return (
    <SectionsWrapper className="bg-next-gen-background bg-cover bg-center lg:min-h-screen">
      <div
        ref={ref}
        className="container relative flex h-full min-h-screen w-full flex-col pt-[152px]"
      >
        <div className="mb-28 flex flex-col items-center lg:mb-0">
          <p
            className={`${supercell.className} text-[42px] text-vivid-orange lg:text-[63px]`}
          >
            Next-gen
          </p>
          <p
            className={`${supercell.className} text-[28px] text-baby-blue lg:text-[39px]`}
          >
            Digital spookiness
          </p>
          <div className="-my-3 block rotate-90 lg:hidden">
            <Icon name="divider" width={2} height={42} />
          </div>
          <p className="quiroh-font text-center font-bold text-steel-blue lg:text-left lg:text-[28px]">
            Leveraging the power of{' '}
            <span className="lg:text-baby-blue">layer-2 blockchain</span>
          </p>
        </div>
        <div className="relative mt-0 flex flex-1 justify-center gap-[100px] px-[33px] lg:mt-32 lg:px-0">
          <div className="relative hidden h-[570px] w-[600px] self-end pl-28 lg:flex">
            <div className="blur-80 absolute left-[50px] top-[50px] z-0 h-[282px] w-[313px] rounded-full bg-electric-blue" />
            <Image
              src={hamster}
              alt="Hamster with flashlight"
              fill
              className="left-[80px]"
              sizes="(min-width: 1300px) 600px"
            />
          </div>
          <div className="mb-[160px] h-max self-end rounded-[30px] bg-next-gen-card-mobile p-0 text-pale-blue backdrop-blur-[11px] lg:rounded-[35px] lg:bg-next-gen-card">
            <ul className="flex flex-col">
              {info.map((item, index) => (
                <li
                  onMouseEnter={() => setActiveItem(index)}
                  key={item.title}
                  className={`${activeItem === index ? item.hoverClass : ''} relative flex items-center ${index === 0 ? 'rounded-tl-[35px]' : ''} ${index === 3 ? 'rounded-bl-[35px]' : ''} border-b border-b-frosted-white px-7 py-[25px] hover:bg-transparent lg:border-b-0 lg:before:absolute lg:before:-left-full lg:before:top-0 lg:before:size-full lg:before:bg-transparent`}
                >
                  {!isMobile && (
                    <div
                      className={clsx(
                        `pointer-events-none absolute ${item.flashlight}`,
                        {
                          'opacity-1': activeItem === index,
                          'opacity-0': activeItem !== index,
                        },
                      )}
                    >
                      <Icon
                        name={item.light as keyof typeof iconsList}
                        viewBox="0 0 224 280"
                      />
                    </div>
                  )}
                  <div className="hidden lg:flex">
                    <Icon
                      name={item.icon as keyof typeof iconsList}
                      width={84}
                      height={82}
                    />
                  </div>
                  <div>
                    <p
                      className={`${supercell.className} text-soft-sky-blue 2xl:text-[28px]`}
                    >
                      {item.title}
                    </p>
                    <p className="quiroh-font mt-3 lg:mt-0 2xl:text-lg">
                      {item.description}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </SectionsWrapper>
  );
}
