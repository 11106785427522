const data = [
  {
    year: 2024,
    quarter: 'Q3',
    className: 'rotate-[-2.033deg]',
    list: [
      'Community Building',
      'Smart Contract Creation',
      'Start Leaderboard',
      'Kickstarting Development',
    ],
  },
  {
    year: 2024,
    quarter: 'Q4',
    className: 'rotate-[-0.611deg]',
    list: [
      'MVP Product Launch',
      'Referral Program Implementation',
      'Wallet Integration',
      'Staking Mechanism Introduction',
    ],
  },
  {
    year: 2025,
    quarter: 'Q1',
    className: 'rotate-[0.836deg]',
    list: [
      'Beta Version Release',
      'Strategic Partnerships',
      'Ecosystem Development',
      'Cloud Infrastructure Setup',
    ],
  },
  {
    year: 2025,
    quarter: 'Q2',
    className: 'rotate-[1.36deg]',
    list: [
      'Game Optimization and Feature Enhancements',
      'AI-Generated Levels',
      'Full Game Launch',
      'Mobile Release',
    ],
  },
];

export default data;
