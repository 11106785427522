import Card from '@/app/ui/sections/roadmap/Card';
import data from '@/app/ui/sections/roadmap/data';

export default function DesktopCards() {
  return (
    <div className="flex justify-center gap-[43px]">
      {data.map((card) => (
        <Card
          className={card.className}
          year={card.year}
          quarter={card.quarter}
          key={`${card.year}-${card.quarter}`}
          list={card.list}
        />
      ))}
    </div>
  );
}
