'use client';

import SectionsWrapper from '@/app/ui/SectionsWrapper';
import { supercell } from '@/app/ui/font';
import Icon from '@/app/ui/icons/Icon';
import { useEffect, useRef } from 'react';
import anime from 'animejs/lib/anime';
import { Dict } from '@/app/types/Dict';
import { useNavigation } from '@/app/contexts/Navigation';
import DesktopCards from '@/app/ui/sections/coin-token/DesktopCards';
import useWindowSize from '@/app/hooks/useWindowSize';
import MobileCards from '@/app/ui/sections/coin-token/MobileCards';
import Image from 'next/image';
import coinImg from './images/coin.webp';
import coinLight from './images/coin_light.webp';
import base from './images/base.png';
import onchain from './images/onchain-summer.png';

const description =
  'Earn $GOIN by escaping haunted house and participating in Airdrops and Sharing/ Creating social media posts for massive rewards!';

export default function CoinTokenSection() {
  const coin = useRef<HTMLImageElement | null>(null);
  const coinLightRef = useRef<HTMLImageElement | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const { isMobile } = useWindowSize();
  const { setRefs } = useNavigation();

  useEffect(() => {
    if (ref.current) {
      setRefs?.((prev) => ({ ...prev, coin: ref.current }));
    }
  }, [ref]);

  useEffect(() => {
    let coinAnimation: Dict;
    let lightAnimation: Dict;
    let lightRotation: Dict;

    const handleAnimation = (entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        lightAnimation = anime({
          targets: coinLightRef.current,
          scale: 1.6,
          duration: 1000,
          autoplay: true,
          complete: () => {
            lightRotation = anime({
              targets: coinLightRef.current,
              rotate: '1turn',
              easing: 'linear',
              duration: 20000,
              loop: true,
            });
          },
        });
        coinAnimation.play();
      } else {
        coinAnimation.seek(0);
        coinAnimation.pause();
        if (lightAnimation) {
          lightAnimation.seek(0);
          lightAnimation.pause();
        }
        if (lightRotation) {
          lightRotation.seek(0);
          lightRotation.pause();
        }
      }
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        handleAnimation(entry);
      },
      {
        root: null,
        rootMargin: '50px',
        threshold: 0.5,
      },
    );

    if (coin.current) {
      observer.observe(coin.current);
      coinAnimation = anime({
        targets: coin.current,
        scale: 1,
        duration: 1000,
        autoplay: false,
      });
    }

    return () => {
      if (coin.current && coinLightRef.current) {
        observer.unobserve(coin.current);
      }
    };
  }, []);

  return (
    <SectionsWrapper className="h-full min-h-screen bg-token-background bg-cover bg-center">
      <div
        ref={ref}
        className="container relative h-full w-full pt-[125px] lg:min-h-screen lg:pt-0"
      >
        <div className="flex w-full flex-col gap-[90px] pt-[7rem] lg:w-[95%]">
          <div className="relative flex flex-col-reverse justify-center gap-[46px] lg:flex-row lg:gap-[130px]">
            <div className="relative flex min-h-[250px] items-center justify-center lg:hidden">
              <div className="absolute top-0">
                <Icon name="tokenBlock" width={366} height={224} />
              </div>
              <p className="quiroh-font relative w-full max-w-[270px] text-center text-base leading-[150%] text-baby-blue">
                {description}
              </p>
            </div>
            <div
              style={{ transform: 'scale(0) rotate(0)' }}
              className="relative ml-0 flex size-[300px] self-center lg:ml-28 lg:size-[350px]"
              ref={coin}
            >
              <Image
                ref={coinLightRef}
                src={coinLight}
                alt="light"
                fill
                style={{ transform: 'scale(0)' }}
              />
              <Image src={coinImg} alt="coin" fill />
            </div>
            <div className="text-center lg:text-left">
              <h1
                className={`${supercell.className} text-[42px] uppercase leading-[115%] text-baby-blue lg:text-[3.9rem]`}
              >
                <span className="text-vivid-orange">$goin</span> token
              </h1>
              <h2
                className={`${supercell.className} text-[2.43rem] leading-[115%] text-baby-blue`}
              >
                launching on Base
              </h2>
              <p className="quiroh-font mt-[30px] hidden max-w-[521px] text-[24px] leading-[150%] text-baby-blue lg:block">
                {description}
              </p>
            </div>
          </div>
          {isMobile ? <MobileCards /> : <DesktopCards />}
          <div className="relative mx-auto mb-[35px] flex justify-center lg:w-[1024px]">
            <span className="absolute -bottom-3 -left-[30px] lg:-left-[50px] lg:bottom-0">
              <Icon
                name="leftWreaths"
                width={isMobile ? 128 : 191}
                height={isMobile ? 232 : 346}
                viewBox="0 0 191 346"
              />
            </span>
            <Icon
              name={isMobile ? 'smallWinnerBlock' : 'winnerBlock'}
              width={!isMobile ? 1024 : 313}
              height={!isMobile ? 334 : 206}
              viewBox={isMobile ? '0 0 313 206' : '0 0 1089 334'}
            />
            <span className="absolute -bottom-3 -right-[30px] lg:-right-[50px] lg:bottom-0">
              <Icon
                name="rightWreaths"
                width={isMobile ? 129 : 191}
                height={isMobile ? 232 : 346}
                viewBox="0 0 191 346"
              />
            </span>
            <div className="absolute bottom-0 flex h-full flex-col items-center lg:w-[1089px]">
              <p
                className={`${supercell.className} pt-[25px] text-[28px] leading-normal text-vivid-orange lg:text-[63px]`}
              >
                #1 Winner
              </p>
              <p
                className={`text-[16px] text-baby-blue lg:text-[39px] ${supercell.className} w-[181px] text-center lg:w-full`}
              >
                Base Onchain Summer Buildathon
              </p>
              <p className="quiroh-font mt-[23px] w-[170px] text-center text-xs leading-normal text-baby-blue lg:mt-0 lg:w-full lg:text-[24px]">
                For an engaging adventure game onchain
              </p>
            </div>
            <div className="absolute -bottom-3 flex gap-[23px] lg:-bottom-7 lg:h-[73px]">
              <Image
                src={base}
                alt="Base Logo"
                width={isMobile ? 90 : 210}
                height={isMobile ? 31 : 73}
              />
              <Image
                src={onchain}
                alt="On chain Logo"
                width={210}
                height={72}
                className="hidden lg:block"
              />
            </div>
          </div>
        </div>
      </div>
    </SectionsWrapper>
  );
}
