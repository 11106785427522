import { ReactNode } from 'react';
import clsx from 'clsx';

export default function SectionsWrapper({
  className = '',
  children,
}: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <section
      className={clsx(`${className} relative min-h-screen`, {
        // 'lg:h-screen': !className.includes('h-'),
      })}
    >
      {children}
    </section>
  );
}
