'use client';

import { ButtonProps } from '@/app/ui/nav/types';
import Link from 'next/link';

export default function Button(props: ButtonProps) {
  const {
    type = "button",
    children,
    onClick,
    className = "",
  } = props;
  if (type === "link") {
    const { href = "/" } = props;
    return (
      <Link
        onClick={(e) => {
          e.preventDefault();
          onClick?.();
        }}
        href={href}
        className={className}
      >
        {children}
      </Link>
    );
  }
  return (
    <button
      className={className}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
      type="button"
    >
      {children}
    </button>
  );
}
