import dynamic from 'next/dynamic';
import clsx from 'clsx';

export const iconsList = {
  divider: dynamic(() => import('@/app/ui/icons/svg/Divider')),
  play: dynamic(() => import('@/app/ui/icons/svg/Play')),
  base: dynamic(() => import('@/app/ui/icons/svg/Base')),
  discord: dynamic(() => import('@/app/ui/icons/svg/Discord')),
  telegram: dynamic(() => import('@/app/ui/icons/svg/Telegram')),
  twitter: dynamic(() => import('@/app/ui/icons/svg/Twitter')),
  bigCoin: dynamic(() => import('@/app/ui/icons/svg/BigCoin')),
  card: dynamic(() => import('@/app/ui/icons/svg/Card')),
  nail: dynamic(() => import('@/app/ui/icons/svg/Nail')),
  smallCoin: dynamic(() => import('@/app/ui/icons/svg/SmallCoin')),
  controller: dynamic(() => import('@/app/ui/icons/svg/Controller')),
  ghost: dynamic(() => import('@/app/ui/icons/svg/Ghost')),
  doors: dynamic(() => import('@/app/ui/icons/svg/Doors')),
  roadmapHang: dynamic(() => import('@/app/ui/icons/svg/RoadmapHang')),
  bigCard: dynamic(() => import('@/app/ui/icons/svg/BigCard')),
  roadmapBlock: dynamic(() => import('@/app/ui/icons/svg/RoadmapBlock')),
  dot: dynamic(() => import('@/app/ui/icons/svg/Dot')),
  burger: dynamic(() => import('@/app/ui/icons/svg/Burger')),
  tokenBlock: dynamic(() => import('@/app/ui/icons/svg/TokenBlock')),
  roadmapHangSmall: dynamic(
    () => import('@/app/ui/icons/svg/RoadmapHangMobile'),
  ),
  roadmapBlockMobile: dynamic(
    () => import('@/app/ui/icons/svg/RoadmapBlockMobile'),
  ),
  firstLight: dynamic(() => import('@/app/ui/icons/svg/lights/First')),
  secondLight: dynamic(() => import('@/app/ui/icons/svg/lights/Second')),
  thirdLight: dynamic(() => import('@/app/ui/icons/svg/lights/Third')),
  fourthLight: dynamic(() => import('@/app/ui/icons/svg/lights/Fourth')),
  chart: dynamic(() => import('@/app/ui/icons/svg/Chart')),
  eth: dynamic(() => import('@/app/ui/icons/svg/crypto/Eth')),
  usdt: dynamic(() => import('@/app/ui/icons/svg/crypto/Usdt')),
  usdc: dynamic(() => import('@/app/ui/icons/svg/crypto/Usdc')),
  smallLogo: dynamic(() => import('@/app/ui/icons/svg/SmallLogo')),
  close: dynamic(() => import('@/app/ui/icons/svg/Close')),
  back: dynamic(() => import('@/app/ui/icons/svg/Back')),
  trust: dynamic(() => import('@/app/ui/icons/svg/wallets/Trust')),
  coinbase: dynamic(() => import('@/app/ui/icons/svg/wallets/Coinbase')),
  metamask: dynamic(() => import('@/app/ui/icons/svg/wallets/Metamask')),
  tryAgain: dynamic(() => import('@/app/ui/icons/svg/wallets/TryAgain')),
  warning: dynamic(() => import('@/app/ui/icons/svg/Warning')),
  copy: dynamic(() => import('@/app/ui/icons/svg/Copy')),
  error: dynamic(() => import('@/app/ui/icons/svg/Error')),
  success: dynamic(() => import('@/app/ui/icons/svg/Success')),
  heroSaleBoard: dynamic(() => import('@/app/ui/icons/svg/HeroSaleBoard')),
  winnerBlock: dynamic(() => import('@/app/ui/icons/svg/WinnerBlock')),
  rightWreaths: dynamic(() => import('@/app/ui/icons/svg/RightWreaths')),
  leftWreaths: dynamic(() => import('@/app/ui/icons/svg/LeftWreaths')),
  smallWinnerBlock: dynamic(
    () => import('@/app/ui/icons/svg/SmallWinnerBlock'),
  ),
  analyticsInsight: dynamic(
    () => import('@/app/ui/icons/svg/featured/AnalyticsInsight'),
  ),
  bitcoinist: dynamic(() => import('@/app/ui/icons/svg/featured/Bitcoinist')),
  bscn: dynamic(() => import('@/app/ui/icons/svg/featured/Bscn')),
  coinPedia: dynamic(() => import('@/app/ui/icons/svg/featured/CoinPedia')),
  coinspeaker: dynamic(() => import('@/app/ui/icons/svg/featured/Coinspeaker')),
  cryptoNews: dynamic(() => import('@/app/ui/icons/svg/featured/CryptoNews')),
  digitalJournal: dynamic(
    () => import('@/app/ui/icons/svg/featured/DigitalJournal'),
  ),
  newsBtc: dynamic(() => import('@/app/ui/icons/svg/featured/NewsBtc')),
  newsCrypto: dynamic(() => import('@/app/ui/icons/svg/featured/NewsCrypto')),
  newsFlash: dynamic(() => import('@/app/ui/icons/svg/featured/NewsFlash')),
  techBullion: dynamic(() => import('@/app/ui/icons/svg/featured/TechBullion')),
  utoday: dynamic(() => import('@/app/ui/icons/svg/featured/Utoday')),
  zyCrypto: dynamic(() => import('@/app/ui/icons/svg/featured/ZyCrypto')),
  caretRight: dynamic(() => import('@/app/ui/icons/svg/CaretRight')),
};
type IconProps = {
  name: keyof typeof iconsList;
  width?: number | 'full';
  height?: number | 'full';
  viewBox?: string;
  className?: string;
};

export default function Icon(props: IconProps) {
  const { name, width, height, viewBox, className } = props;

  const RenderIcon = iconsList[name];
  const calcViewBox = `0 0 ${width} ${height}`;

  return (
    <svg
      className={clsx(className ?? '', {
        'w-full': width === undefined,
        'h-full': height === undefined,
      })}
      width={width}
      height={height}
      viewBox={viewBox ?? calcViewBox}
      fill="none"
    >
      <RenderIcon />
    </svg>
  );
}
