import { StateSetter } from '@/app/types/StateSetter';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { Dict } from '@/app/types/Dict';

type NavigationContextType = {
  refs?: {
    hero?: HTMLDivElement | null;
    coin?: HTMLDivElement | null;
    nextgen?: HTMLDivElement | null;
    findway?: HTMLDivElement | null;
    roadmap?: HTMLDivElement | null;
    roadmapSection?: HTMLDivElement | null;
    tokenomics?: HTMLDivElement | null;
  };
  setRefs?: StateSetter<Dict>;
};

const NavigationContext = createContext<NavigationContextType>({});

NavigationContext.displayName = 'Navigation';

export default function NavigationProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [refs, setRefs] = useState<Dict>({});

  const value = useMemo(() => ({ refs, setRefs }), [refs]);

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
}

export function useNavigation() {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within NavigationProvider');
  }
  return context;
}

