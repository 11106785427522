import data from '@/app/ui/sections/tokenomics/data';
import clsx from 'clsx';
import { supercell } from '@/app/ui/font';

export default function Table() {
  return (
    <div className="bg-tokenomics-table mx-auto mt-[43px] w-[282px] rounded-[13px] shadow-[0,4px,24.6px,0px,rgba(0,0,0,0.25)] backdrop-blur-[1px]">
      {data.map((item, index) => (
        <div
          className={clsx('flex justify-between px-[11px] py-[16px]', {
            'border-b border-b-[rgba(170,212,249,0.19)]':
              index !== data.length - 1,
          })}
          key={item.label}
        >
          <p className="quiroh-font text-baby-blue">{item.label}</p>
          <p className={`${supercell.className} text-white`}>{item.value}%</p>
        </div>
      ))}
    </div>
  );
}
