'use client';

import Icon, { iconsList } from '@/app/ui/icons/Icon';
import { supercell } from '@/app/ui/font';
import Marquee from 'react-fast-marquee';
import useWindowSize from '@/app/hooks/useWindowSize';

const featured = [
  {
    link: 'https://www.newsbtc.com/press-releases/gamster-launches-seed-sale-to-back-new-ai-powered-play-to-earn-experience/',
    icon: 'newsBtc',
    width: 245,
  },
  {
    link: 'https://bitcoinist.com/seed-sale-kicks-off-for-gamsters-ai-powered-p2e-platform/',
    icon: 'bitcoinist',
    width: 246,
  },
  {
    link: 'https://cryptonews.com/news/gamster-kicks-off-seed-sale-to-support-cutting-edge-ai-powered-p2e-game/',
    icon: 'cryptoNews',
    width: 226,
  },
  {
    link: 'https://u.today/press-releases/gamster-announces-seed-sale-to-fund-innovative-ai-driven-p2e-platform',
    icon: 'utoday',
    width: 207,
  },
  {
    link: 'https://bsc.news/post/gamster-launches-seed-sale-to-back-revolutionary-ai-powered-play-to-earn-experience',
    icon: 'bscn',
    width: 137,
  },
  {
    link: 'https://www.crypto-news-flash.com/seed-sale-kicks-off-for-gamsters-ai-powered-p2e-platform/',
    icon: 'newsFlash',
    width: 168,
  },
  {
    link: 'https://www.analyticsinsight.net/cryptocurrency-analytics-insight/gamster-kicks-off-seed-sale-to-support-cutting-edge-ai-powered-p2e-game',
    icon: 'analyticsInsight',
    width: 418,
  },
  {
    link: 'https://coinpedia.org/sponsored/gamster-announces-seed-sale-to-fund-innovative-ai-driven-p2e-platform/',
    icon: 'coinPedia',
    width: 183,
  },
  {
    link: 'https://www.coinspeaker.com/gamster-seed-sale-fund-innovative-ai-driven-p2e-platform/',
    icon: 'coinspeaker',
    width: 232,
  },
  {
    link: 'https://zycrypto.com/gamster-launches-seed-sale-to-back-new-ai-powered-play-to-earn-experience/',
    icon: 'zyCrypto',
    width: 230,
  },
  {
    link: 'https://thenewscrypto.com/seed-sale-kicks-off-for-gamsters-ai-powered-p2e-platform/',
    icon: 'newsCrypto',
    width: 330,
  },
  {
    link: 'https://techbullion.com/gamster-kicks-off-seed-sale-to-support-cutting-edge-ai-powered-p2e-game/',
    icon: 'techBullion',
    width: 231,
  },
  {
    link: 'https://www.digitaljournal.com/pr/news/binary-news-network/gamster-announces-seed-sale-fund-1635262377.html#ixzz8lFglbvgK',
    icon: 'digitalJournal',
    width: 371,
  },
];

export default function FeaturedInSection() {
  const { isMobile } = useWindowSize();
  return (
    <div className="align-center flex min-h-[200px] w-full flex-col bg-[#000516] pt-[13px] text-center lg:pb-10 lg:pt-[175px]">
      <p className={`${supercell.className} text-[39px] text-baby-blue`}>
        Featured In
      </p>
      <p className="quiroh-font mb-[45px] text-[24px] text-[rgba(170,212,249,0.8)]">
        Click to read articles
      </p>
      <div>
        <Marquee
          pauseOnHover
          gradient
          gradientColor="#000516"
          gradientWidth={isMobile ? 141 : 501}
        >
          {featured.map((item) => (
            <a key={item.link} href={item.link} target="_blank">
              <Icon
                name={item.icon as keyof typeof iconsList}
                width={item.width}
                height={67}
                className="mx-6"
              />
            </a>
          ))}
        </Marquee>
      </div>
    </div>
  );
}
