'use client';

import SectionsWrapper from '@/app/ui/SectionsWrapper';
import Icon from '@/app/ui/icons/Icon';
import { supercell } from '@/app/ui/font';
import useWindowSize from '@/app/hooks/useWindowSize';
import Table from '@/app/ui/sections/tokenomics/Table';
import Chart from '@/app/ui/sections/tokenomics/Chart';
import { useEffect, useRef } from 'react';
import { useNavigation } from '@/app/contexts/Navigation';
import FaqSection from './faq/FaqSection';

export default function TokenomicsSection() {
  const ref = useRef<HTMLDivElement | null>(null);
  const { setRefs } = useNavigation();
  const { width } = useWindowSize();

  useEffect(() => {
    if (ref.current) {
      setRefs?.((prev) => ({ ...prev, tokenomics: ref.current }));
    }
  }, [ref, setRefs]);
  return (
    <SectionsWrapper className="bg-tokenomics bg-cover bg-center">
      <div
        ref={ref}
        className="absolute left-0 top-0 h-[30px] w-full bg-find-your-way-top bg-cover bg-center"
      />
      <div className="container relative flex min-h-[800px] w-full justify-between pt-[50px] lg:min-h-screen lg:pt-0">
        <div className="w-full lg:absolute lg:left-2/4 lg:top-2/4 lg:-translate-x-2/4 lg:-translate-y-2/4">
          <div className="quiroh-font mt-16 flex w-full flex-col gap-10 text-center text-baby-blue lg:mt-0 lg:gap-0 lg:text-left">
            <h1
              className={`${supercell.className} tekonomics-heading-text text-[42px] lg:text-[63px]`}
            >
              Tokenomics
            </h1>
            <div className="flex flex-row items-center justify-evenly lg:flex-col lg:items-start">
              <div className="mt-0 flex items-center gap-6 lg:mt-16">
                <div>
                  <Icon name="dot" width={18} height={19} />
                </div>
                <p className="text-base lg:text-[24px]">Token: $GOIN</p>
              </div>
              <div className="mt-0 flex items-center gap-6 lg:mt-6">
                <div>
                  <Icon name="dot" width={18} height={19} />
                </div>
                <p className="text-base lg:text-[24px]">Supply: 1B</p>
              </div>
            </div>
          </div>
          <div className="static -top-full right-0 lg:absolute">
            {width < 1280 ? <Table /> : <Chart />}
          </div>
        </div>
      </div>
      <FaqSection />
    </SectionsWrapper>
  );
}
