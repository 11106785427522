import Icon from '@/app/ui/icons/Icon';
import { useEffect, useRef } from 'react';
import { Dict } from '@/app/types/Dict';
import anime from 'animejs/lib/anime';

export default function MobileCards() {
  const cardsRef = useRef<HTMLDivElement | null>(null);
  const firstCard = useRef<HTMLDivElement | null>(null);
  const secondCard = useRef<HTMLDivElement | null>(null);
  const thirdCard = useRef<HTMLDivElement | null>(null);
  const forthCard = useRef<HTMLDivElement | null>(null);

  let leftCards: Dict;
  let rightCards: Dict;

  useEffect(() => {
    const handleAnimation = (entry: Dict) => {
      if (entry.isIntersecting) {
        leftCards.play();
        rightCards.play();
      } else {
        leftCards.pause();
        leftCards.seek(0);
        rightCards.pause();
        rightCards.seek(0);
      }
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        handleAnimation(entry);
      },
      {
        root: null,
        rootMargin: '50px',
        threshold: 0.5
      }
    );

    if (cardsRef.current) {
      observer.observe(cardsRef.current);
      leftCards = anime({
        targets: [firstCard.current, thirdCard.current],
        translateX: '0vw',
        duration: 1000,
        autoplay: false
      });
      rightCards = anime({
        targets: [secondCard.current, forthCard.current],
        translateX: '0vw',
        duration: 1000,
        autoplay: false
      });
    }

    return () => {
      if (cardsRef.current) {
        observer.unobserve(cardsRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={cardsRef}
      className="flex flex-col justify-evenly px-12 sm:w-full"
    >
      <div
        ref={firstCard}
        className="relative flex h-[159px] w-[226px] rotate-[2.854deg] items-center justify-center self-start"
        style={{ transform: 'translateX(-100vw)' }}
      >
        <div className="absolute z-0">
          <Icon name="card" width={226} height={159} viewBox="0 0 225 163" />
        </div>
        <div className="absolute top-1 z-[1]">
          <Icon name="nail" width={39} height={40} />
        </div>
        <p className="quiroh-font relative z-[2] w-11/12 text-center text-[25px] font-bold text-baby-blue">
          Burning mechanism
        </p>
      </div>
      <div
        ref={secondCard}
        className="relative -mt-10 flex h-[159px] w-[226px] rotate-[-1.697deg] items-center justify-center self-end"
        style={{ transform: 'translateX(100vw)' }}
      >
        <div className="absolute z-0">
          <Icon name="card" width={226} height={159} />
        </div>
        <div className="absolute top-1 z-[1]">
          <Icon name="nail" width={39} height={40} />
        </div>
        <p className="quiroh-font relative z-[2] w-11/12 text-center text-[25px] font-bold text-baby-blue">
          Deflationary
        </p>
      </div>
      <div
        ref={thirdCard}
        className="relative -mt-10 flex h-[159px] w-[226px] rotate-[2.064deg] items-center justify-center"
        style={{ transform: 'translateX(-100vw)' }}
      >
        <div className="absolute z-0">
          <Icon name="card" width={226} height={159} />
        </div>
        <div className="absolute top-1 z-[1]">
          <Icon name="nail" width={39} height={40} />
        </div>
        <p className="quiroh-font relative z-[2] w-11/12 text-center text-[25px] font-bold text-baby-blue">
          Fixed supply
        </p>
      </div>
      <div
        ref={forthCard}
        className="relative -mt-10 flex h-[159px] w-[226px] rotate-[-1.36deg] items-center justify-center self-end"
        style={{ transform: 'translateX(100vw)' }}
      >
        <div className="absolute z-0">
          <Icon name="card" width={226} height={159} />
        </div>
        <div className="absolute top-1 z-[1]">
          <Icon name="nail" width={39} height={40} />
        </div>
        <p className="quiroh-font relative z-[2] w-11/12 text-center text-[25px] font-bold text-baby-blue">
          Strong economy
        </p>
      </div>
    </div>
  );
}
