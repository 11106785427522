import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Card from '@/app/ui/sections/roadmap/Card';
import data from '@/app/ui/sections/roadmap/data';

export default function MobileCards() {
  return (
    <div className="flex w-full justify-center">
      <Swiper
        modules={[Pagination, A11y]}
        pagination={{ clickable: true }}
        slidesPerView="auto"
        centeredSlides
        centeredSlidesBounds
        className="h-[380px] !pt-5"
      >
        {data.map((card) => (
          <SwiperSlide
            className="md:!w-2/4 !relative !flex !w-full !justify-center"
            key={`${card.year}-${card.quarter}`}
          >
            <Card
              className={card.className}
              year={card.year}
              quarter={card.quarter}
              list={card.list}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
