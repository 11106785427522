import { supercell } from '@/app/ui/font';
import Icon from '@/app/ui/icons/Icon';
import Button from '@/app/ui/nav/Button';
import clsx from 'clsx';
import { useState } from 'react';
import { toast } from 'react-toastify';

const questions = [
  {
    question: 'What is Gamster?',
    answer: `Gamster is an innovative Play-to-Earn (P2E) game that combines gaming with blockchain technology, allowing players to earn $GOIN tokens while navigating through AI-generated levels in a haunted house. With a focus on teamwork, Gamster challenges players to cooperate and escape while collecting tokens that can be used for real-world rewards. We are looking for investors and partners, offering early investors a chance to be part of Gamster's journey to revolutionize blockchain gaming.`,
  },
  {
    question: 'What solution does Gamster offer on BASE?',
    answer: `Lack of quality projects on base blockchain
Ability to earn money for entrepreneurs and creators either by collecting GOIN in-game directly or by developing their own levels through Gamster‘s SDK.
Unique gameplay experience through AI generated levels
Stable internal economy`,
  },
  {
    question: 'What is Gamster SDK?',
    answer: `Gamster SDK is a powerful Layer-2 toolset designed for entrepreneurs, developers, and gaming enthusiasts of all backgrounds. This innovative SDK allows you to dive into game development, even if you’re not a tech expert, enabling you to build immersive levels, design challenging scenarios, and create unique gaming experiences within the Gamster universe. Best of all, you can monetize your creations and earn from your developments, tapping into the rapidly growing Play-to-Earn industry.`,
  },
  {
    question: 'What is the goal of the project?',
    answer: `The goal of Gamster is to become a leading Play-to-Earn (P2E) platform on the Base blockchain, delivering an unparalleled gaming experience through a combination of AI-driven environments. In the long term, we aim to fully release our SDK, enabling developers to create their own dynamic game environments and expand the Gamster.`,
  },
  {
    question: 'What is your smart contract address?',
    answer: `0x902BED84297DCC988A106d9c321dC2B682b84116`,
  },
];

export default function FaqSection() {
  const [open, setOpen] = useState<string | null>(null);

  return (
    <div className="mt-16 flex min-h-screen flex-col justify-start lg:mt-0">
      <h1
        className={`${supercell.className} text-center text-[42px] leading-[115%] text-baby-blue lg:text-[63px]`}
      >
        <span className="text-vivid-orange">FAQ</span>s
      </h1>
      <div className="mx-auto my-12 max-w-[282px] overflow-hidden rounded-[13px] bg-widget-background lg:mt-40 lg:max-w-[732px] lg:rounded-[31px]">
        {questions.map((q) => (
          <div
            className="quiroh-font grid cursor-pointer grid-cols-[minmax(0,_1fr)_auto] grid-rows-[minmax(0,_1fr)_auto] items-center border-b-white/15 px-[11px] pb-[14px] pt-[14px] font-medium text-baby-blue hover:bg-widget-info-background lg:min-h-[59px] lg:pl-[52px] lg:pr-[35px] lg:text-2xl [&:not(:nth-last-child(-n+1))]:border-b-[1px]"
            key={q.question}
            onClick={() =>
              setOpen((prev) => (prev === q.question ? null : q.question))
            }
          >
            {q.question}
            <div
              className={clsx('transition-all duration-700', {
                'rotate-90': open === q.question,
              })}
            >
              <Icon name="caretRight" width={24} height={24} />
            </div>
            <div
              className={clsx(
                'col-span-2 grid overflow-hidden text-pale-blue transition-all duration-700 lg:text-lg',
                {
                  'max-h-0': open !== q.question,
                  'max-h-96 pt-2 lg:pb-4 lg:pt-5': open === q.question,
                },
              )}
            >
              {q.question === 'What is your smart contract address?' ? (
                <div className="flex w-full overflow-hidden">
                  <p className="quiroh-font flex-1 truncate">{q.answer}</p>
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(q.answer).then(() => {
                        toast('Copied!', { type: 'success' });
                      });
                    }}
                  >
                    <Icon name="copy" width={25} height={25} />
                  </Button>
                </div>
              ) : (
                q.answer
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
